@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

.footer-homepage {
  position: absolute;
  bottom: 0px;
  width: calc(100% - 325px);
}

.App {
  text-align: center;
}

html {
  scroll-behavior: smooth; /* Active le défilement en douceur */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

ul.list-disque li::before {
  content: "•";
  color: #f48d26;
  margin-right: 0.5rem;
  font-size: 28px;
  position: relative;
  top: 5px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.belee-gradient-color {
  @apply bg-gradient-to-br from-[#73ADB6] to-[#1E1E1C];
}

.belee-gradient-color-input {
  @apply bg-gradient-to-br from-[#3f5255] to-[#36363a];
}
.button-belee {
  @apply h-12 bg-green-belee font-medium rounded-md hover:bg-dark-green-belee;
}
.belee-gradient-dark-to-green {
  @apply bg-gradient-to-bl from-[#171c1d] to-[#70A7A9];
}

.btn-sp-belee {
  @apply h-12 rounded-lg text-xl px-10 shadow-md shadow-gray-600;
}
.btn-gray-belee {
  @apply border h-12 rounded-lg border-gray-400 bg-gray-500 text-sm hover:bg-gray-700;
}

.btn-gray-2 {
  @apply h-11 rounded-lg text-white flex justify-start items-center bg-[#77787C] px-6 hover:bg-gray-500 hover:text-green-belee;
}

@-webkit-keyframes preloader-inside-white {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }

  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes preloader-inside-white {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }

  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@-webkit-keyframes preloader-inside-red {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }

  30% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }

  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes preloader-inside-red {
  0% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }

  30% {
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }

  100% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

.preloader {
  display: inline-block;
  z-index: 9999;
  text-align: center;
  opacity: var(--loader-opacity);
}

.preloader .preloader-container {
  display: inline-block;
  top: 44%;
}

.preloader .preloader-container .animated-preloader {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
  background: #6dc8b8;
  border-radius: 50em;
}

.preloader .preloader-container .animated-preloader:after {
  content: "";
  display: inline-block;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50em;
  background: white;
  -webkit-animation: preloader-inside-white 1s ease-in-out infinite;
  -ms-animation: preloader-inside-white 1s ease-in-out infinite;
  animation: preloader-inside-white 1s ease-in-out infinite;
}

.preloader .preloader-container .animated-preloader:before {
  content: "";
  display: inline-block;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  border-radius: 50em;
  background: #6dc8b8;
  -webkit-animation: preloader-inside-red 1s ease-in-out infinite;
  -ms-animation: preloader-inside-red 1s ease-in-out infinite;
  animation: preloader-inside-red 1s ease-in-out infinite;
}

.react-calendar__navigation {
  @apply flex justify-between items-center;
}
.react-calendar__navigation .react-calendar__navigation__prev2-button {
  @apply hidden;
}
.react-calendar__navigation .react-calendar__navigation__next-button {
  @apply text-3xl font-light;
}

.react-calendar__navigation .react-calendar__navigation__prev-button {
  @apply text-3xl font-light;
}
.react-calendar__navigation .react-calendar__navigation__next2-button {
  @apply hidden;
}

.react-calendar__month-view__days__day--neighboringMonth {
  @apply text-gray-600 opacity-0;
}
.react-calendar__month-view__weekdays__weekday {
  @apply text-[9px] uppercase my-2 decoration-0;
}

.react-calendar__month-view__weekdays__weekday > abbr {
  text-decoration: none;
}

.react-calendar__tile--active {
  @apply bg-green-belee rounded-full;
}
.react-calendar__tile {
  @apply py-2 text-sm;
}
.react-calendar__navigation__label::first-letter {
  text-transform: uppercase;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.svg-loader {
  height: 30vmin;
  padding: 3vmin 20vmin;
  vertical-align: top;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

select option::before {
  content: "";
  display: inline-block;
  width: 20px; /* Ajustez la largeur en fonction de vos besoins */
  height: 20px; /* Ajustez la hauteur en fonction de vos besoins */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.bg-home {
  /* background-image: url("../public/backgroundgeneral.jpg"); */
  background-image: url("../public/images/guide_installation/imageFondApp.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-attachment: fixed;
}

.bg-home-a4 {
  background-image: url("../public/backgroundgeneral.jpg");
  background-size: 794px 1123px;
  background-repeat: no-repeat;
  background-position: top center;
}

.bg-app {
  background-image: url("../public/images/guide_installation/imageFondApp.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.dropdown-menu::-webkit-scrollbar {
  width: 5px;
}

.dropdown-menu::-webkit-scrollbar-track {
  background: #d2d5db;
}

.dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #888888; /* Couleur du bouton de défilement */
  border-radius: 5px; /* Ajoutez une bordure arrondie si nécessaire */
}

.custom-shadow-container {
  box-shadow: rgba(50, 50, 93, 0.1) 0px -00px 0px -00px,
    rgba(0, 0, 0, 0.3) 0px 30px 50px -30px,
    rgba(0, 0, 0, 0.1) 50px 0px 30px -20px;
}

.shadow-container {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 10px 0px;
}

/*animation desktop*/
.fadeIn {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.hover-grow {
  transition: transform 0.3s ease-in-out;
}

.hover-grow:hover {
  transform: scale(1.1);
}

.rotate-on-hover {
  transition: transform 0.5s ease-in-out;
}

.rotate-on-hover:hover {
  transform: rotate(360deg);
}

/*btn animation*/
.button-animate {
  position: relative;
  overflow: hidden;
  background-color: #fa9124;
  color: white;
  border: none;
  border-radius: 9999px;
  padding: 0.5rem 1rem;
  box-shadow: 0 4px 15px rgba(250, 145, 36, 0.5);
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.button-animate::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.4)
  );
  opacity: 0;
  transform: rotate(45deg);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.button-animate:hover::before {
  opacity: 1;
  transform: rotate(45deg) translate(50%, 50%);
}

.button-animate:hover {
  background-color: #e5891d;
  transform: translateY(-3px) rotateX(5deg) rotateY(5deg);
}

.button-animate:active {
  /* transform: translateY(1px) rotateX(0) rotateY(0); */
  box-shadow: 0 4px 10px rgba(250, 145, 36, 0.5);
}

/*flag animation*/
.flag-icon {
  border: 2px solid;
  border-radius: 9999px; /* Rendu arrondi */
  width: 1.75rem; /* Taille des images */
  height: 1.75rem; /* Taille des images */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.flag-icon:hover {
  transform: rotate(20deg) scale(1.2); /* Rotation et agrandissement */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Ombre */
}

.flag-icon:active {
  transform: scale(1.1); /* Réduction légère lors du clic */
  transition: transform 0.1s; /* Transition rapide pour l'effet de clic */
}

/* disponible service */
.service-container {
  position: relative;
}

.non-dispo-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 50%;
}

.service-container:hover .non-dispo-text {
  opacity: 1;
}

/* menu animation */
@keyframes slideIn {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.menu-item {
  display: inline-block;
  position: relative;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
  animation: slideIn 0.5s ease-in-out forwards;
}

.menu-item::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -2px;
  width: 0;
  height: 2px;
  background: #fa9124;
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
}

.menu-item:hover {
  color: #fa9124;
  transform: translateY(-3px);
}

.menu-item:hover::after {
  width: 100%;
  left: 0;
}

/* image */
.animated-image {
  transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
}

.animated-image:hover {
  transform: translateY(-2px) scale(1);
  filter: blur(0.1px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

.animated-image:not(:hover) {
  transition: transform 0.3s ease-in-out;
}

/* page */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 2s cubic-bezier(0.4, 0, 0.2, 1);
}

/**/
button {
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
}
